
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getStayRoomForm, reserveStayRoom } from '@/api/stayRoom';
import { addReservationClient } from '@/api/reservationClient';
import { parseConcatNumber } from '@/filters';
import { getStayReservation } from '@/utils/cookies';
import { ElForm } from 'element-ui/types/form';
import { UserModule } from '@/store/user';
import { getUserInfo } from '@/api/user';
import { getUserCouponList } from '@/api/userCoupon';

@Component({
  components: {
  },
})

export default class extends Vue {
  created() {
    if (!UserModule.token) {
      this.$router.push({ name: 'Login' });
    }
  }

  mounted() {
    this.getStayRoomForm();
    this.getUserInfo();
    this.getCouponList();
  }

  @Watch('reservationAgree', { deep: true })
  private handleChangeAgree() {
    if (
      this.reservationAgree.agree1
      && this.reservationAgree.agree2
      && this.reservationAgree.agree3
      && this.reservationAgree.agree4
    ) {
      this.reservationAgree.agreeAll = true;
    } else {
      this.reservationAgree.agreeAll = false;
    }
  }

  private opendialog = false;

  private opendialogsecond = false;

  private opendialogthird = false;

  private opendialogfourth = false;

  private formInfoLoading = true;

  private personalInfoVisible = false;

  private couponVisible = false;

  private personalInfo = {
    name: '',
    phone: '',
  }

  private couponList = [];

  private formData: any = {
    people: 1,
    useDate: '', // 사용일
    endDate: '', // 사용 종료일
    name: '',
    phone: '',
    price: 0,
    adult: 2,
    child: 0,
    couponIdx: null,
    couponName: '',
    discountPrice: 0,
    memo: '',
  };

  private formInfo: any = {
    useDate: '',
    endDate: '',
    checkIn: '',
    checkOut: '',
    itemName: '',
    price: 0,
    adult: 2,
    child: 0,
    maxPointPercent: 0,
  };

  private validatePhone: any = (rule: any, value: string, callback: Function) => {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    if (value === '') return callback(new Error('휴대폰번호를 입력해주세요.'));
    if (!regPhone.test(value)) callback(new Error('휴대폰번호 형식에 맞게 입력해주세요.'));
    return callback();
  }

  private rules = {
    name: [
      { required: true, message: '예약자 이름을 입력해주세요.', trigger: 'blur' },
    ],
    phone: [
      { required: true, validator: this.validatePhone, trigger: 'blur' },
    ],
  }

  private reservationAgree = {
    agreeAll: false,
    agree1: false,
    agree2: false,
    agree3: false,
    agree4: false,
  };

  private userInfo = {
    actualName: '',
    concatNumber: '',
    point: 0,
  }

  private clientKey = process.env.VUE_APP_TOSS_CLIENT_KEY;

  private customerKey = (window as any).PaymentWidget.ANONYMOUS;

  private paymentWidget = (window as any).PaymentWidget(this.clientKey, this.customerKey);

  private paymentMethodsWidget: any = null;

  private couponPoint: any = 1;

  private useCoupon(coupon: any) {
    this.formData.couponIdx = coupon.idx;
    this.formData.couponName = coupon.couponName;
    this.formData.discountPrice = coupon.couponPrice;
    this.couponVisible = false;
  }

  private cancelCoupon(coupon: any) {
    this.formData.couponIdx = null;
    this.formData.couponName = '';
    this.formData.discountPrice = 0;
  }

  private filterPhone() {
    this.personalInfo.phone = parseConcatNumber(this.personalInfo.phone);
  }

  private getStayRoomForm() {
    const reservation = getStayReservation();
    this.formData = {
      ...this.formData,
      roomIdx: this.$route.params.roomIdx,
      adult: reservation.adult,
      child: reservation.child,
    };
    getStayRoomForm(this.$route.params.roomIdx, reservation).then((res) => {
      if (!res.data.usable || res.data.closeStatus || res.data.remainRoom === 0) {
        this.$message.error('예약할 수 없는 객실입니다.');
        this.$router.go(-1);
      }
      this.formInfoLoading = false;
      this.formInfo = {
        ...res.data,
      };
      this.formData = {
        ...this.formData,
        useDate: res.data.useDate,
        endDate: res.data.endDate,
      };
      this.initTossPayments();
    });
  }

  private handleApplyPersonalInfo() {
    (this.$refs.personalForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.formData.name = this.personalInfo.name;
        this.formData.phone = this.personalInfo.phone;
        this.personalInfoVisible = false;
      }
    });
  }

  private handleAllAgree(allAgree: boolean) {
    this.reservationAgree.agree1 = allAgree;
    this.reservationAgree.agree2 = allAgree;
    this.reservationAgree.agree3 = allAgree;
    this.reservationAgree.agree4 = allAgree;
  }

  private handleReserve() {
    if (!this.formData.name || !this.formData.phone) {
      this.$message.warning('예약자 정보를 입력해주세요.');
      return;
    }
    if (
      !this.reservationAgree.agree1
      || !this.reservationAgree.agree2
      || !this.reservationAgree.agree3
      || !this.reservationAgree.agree4
    ) {
      this.$message.warning('약관에 동의해주세요.');
      return;
    }

    this.formInfoLoading = true;
    reserveStayRoom(this.formData).then(async (res) => {
      this.formInfoLoading = false;
      if (res.data.price >= 1000) {
        this.paymentMethodsWidget.updateAmount(res.data.price);
        this.paymentWidget.requestPayment({
          orderId: res.data.reservationCode,
          orderName: `${this.formInfo.itemName} - ${this.formInfo.useDate} ~ ${this.formInfo.endDate}`,
          successUrl: process.env.VUE_APP_TOSS_SUCCESS_URL,
          failUrl: window.location.href,
        });
      } else {
        this.$message.warning('1000원 이상부터 결제가 가능합니다.');
      }
      // else {
      //   this.$router.push({ name: 'mypageOrder' });
      // }
    }).catch((err) => {
      this.$message.error(err.response.data.message);
      this.formInfoLoading = false;
    });
  }

  private getFewDays(start: any, end: any) {
    let fewDays = 1;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    fewDays = endDate.diff(startDate, 'days');
    return fewDays;
  }

  private initTossPayments() {
    this.paymentMethodsWidget = this.paymentWidget.renderPaymentMethods(
      '#payment-method',
      { value: this.formInfo.price },
      { variantKey: 'DEFAULT' }, // 렌더링하고 싶은 결제 UI의 variantKey
    );
    this.paymentWidget.renderAgreement('#agreement');
  }

  private getUserInfo() {
    getUserInfo().then((res) => {
      this.formData.name = res.data.actualName;
      this.formData.phone = res.data.concatNumber;
      this.personalInfo.name = res.data.actualName;
      this.personalInfo.phone = res.data.concatNumber;
      this.userInfo = res.data;
    });
  }

  /* eslint-disable */
  private getMaxUsePoint() {
    const userPoint = this.userInfo.point;
    const maxUsePoint = Math.round(this.formInfo.price / 100 * this.formInfo.maxPointPercent);
    console.log(userPoint, maxUsePoint);
    if (userPoint > maxUsePoint) return maxUsePoint;
    return userPoint;
  }
  /* eslint-enable */

  private getCouponList() {
    getUserCouponList().then((res) => {
      this.couponList = res.data;
    });
  }
}
